import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

function PrivateRoute({ children, requiredPermission }) {
  const { currentUser, hasPermission } = useAuth();

  // If not logged in, redirect to login
  if (!currentUser) {
    console.log('Access denied: No user logged in');
    return <Navigate to="/login" />;
  }

  // If no specific permission required, allow access
  if (!requiredPermission) {
    console.log('Access granted: No specific permission required');
    return children;
  }

  // Check if user has required permission
  if (!hasPermission(requiredPermission)) {
    console.log('Access denied: Missing required permission', {
      required: requiredPermission,
      user: currentUser.username,
      userPermissions: currentUser.permissions
    });
    return <Navigate to="/dashboard" />;
  }

  console.log('Access granted:', {
    user: currentUser.username,
    permission: requiredPermission
  });
  return children;
}

export default PrivateRoute;
