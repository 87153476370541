import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';

const AuthContext = createContext();
const API_URL = process.env.REACT_APP_API_URL || 'https://petdb.yoda-md.workers.dev';

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const apiCall = useCallback(async (endpoint, method = 'GET', body = null, token = null) => {
    try {
      const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      };

      if (token) {
        headers['Authorization'] = `Bearer ${token}`;
      }

      const config = {
        method,
        headers,
        mode: 'cors',
        credentials: 'omit', // Changed to 'omit' since we're using token-based auth
      };

      if (body) {
        config.body = JSON.stringify(body);
      }

      const url = `${API_URL}${endpoint}`;
      console.log(`Making API call to ${url}`, {
        method,
        headers: { ...headers, Authorization: token ? 'Bearer [HIDDEN]' : undefined },
        body
      });

      const response = await fetch(url, config);
      console.log('Response status:', response.status);

      // Log response headers for debugging
      const responseHeaders = {};
      response.headers.forEach((value, key) => {
        responseHeaders[key] = value;
      });
      console.log('Response headers:', responseHeaders);

      if (!response.ok) {
        const errorText = await response.text();
        console.error('API error response:', {
          status: response.status,
          statusText: response.statusText,
          body: errorText
        });

        let errorData;
        try {
          errorData = JSON.parse(errorText);
        } catch (e) {
          errorData = { error: errorText };
        }

        throw new Error(errorData.error || `API call failed with status ${response.status}`);
      }

      const contentType = response.headers.get('content-type');
      if (contentType && contentType.includes('application/json')) {
        const data = await response.json();
        console.log('Response data:', { ...data, token: data.token ? '[HIDDEN]' : undefined });
        return data;
      } else {
        const text = await response.text();
        console.log('Response text:', text);
        try {
          return JSON.parse(text);
        } catch (e) {
          return { data: text };
        }
      }
    } catch (error) {
      console.error(`API call error: ${endpoint}`, error);
      throw error;
    }
  }, []);

  const logout = useCallback(() => {
    console.log('Logging out user');
    localStorage.removeItem('token');
    setCurrentUser(null);
  }, []);

  const fetchUserData = useCallback(async (token) => {
    try {
      console.log('Fetching user data with token:', token ? '[HIDDEN]' : 'No token');
      const payload = JSON.parse(atob(token.split('.')[1]));
      const userId = payload.userId;
      console.log('Decoded user ID:', userId);

      const userData = await apiCall(`/api/users/${userId}`, 'GET', null, token);
      console.log('User data fetched successfully', { ...userData, token: '[HIDDEN]' });

      // Map roles to permissions
      const permissions = mapRolesToPermissions(userData.roles);
      console.log('Mapped permissions:', permissions);

      setCurrentUser({ ...userData, token, permissions });
    } catch (error) {
      console.error('Error fetching user data:', error);
      logout();
    } finally {
      setLoading(false);
    }
  }, [apiCall, logout]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      console.log('Token found in localStorage');
      fetchUserData(token);
    } else {
      console.log('No token found in localStorage');
      setLoading(false);
    }
  }, [fetchUserData]);

  const login = async (username, password) => {
    try {
      console.log('Attempting login for username:', username);
      const data = await apiCall('/auth', 'POST', { username, password });
      console.log('Login response:', { success: !!data.token });

      if (data.token) {
        console.log('Login successful, token received');
        localStorage.setItem('token', data.token);
        await fetchUserData(data.token);
        return { token: data.token };
      } else {
        console.log('Login failed, no token received');
        return { error: data.error || 'Login failed' };
      }
    } catch (error) {
      console.error('Login error:', error);
      return { error: error.message || 'An unexpected error occurred' };
    }
  };

  const mapRolesToPermissions = (roles) => {
    if (!Array.isArray(roles)) {
      console.error('Invalid roles format:', roles);
      return [];
    }

    const rolePermissions = {
      'superadmin': [
        'user.create', 'user.read', 'user.update', 'user.delete', 'user.list',
        'pet.create', 'pet.read', 'pet.update', 'pet.delete', 'pet.list',
        'clinic.create', 'clinic.read', 'clinic.update', 'clinic.delete', 'clinic.list',
        'vaccination.create', 'vaccination.read', 'vaccination.update', 'vaccination.delete', 'vaccination.list',
        'health.create', 'health.read', 'health.update', 'health.delete', 'health.list',
        'volunteer.create', 'volunteer.read', 'volunteer.update', 'volunteer.delete', 'volunteer.list',
        'announcement.create', 'announcement.read', 'announcement.update', 'announcement.delete', 'announcement.list',
        'audit.view', 'system.configure', 'stats.view'
      ],
      'staff': [
        'pet.create', 'pet.read', 'pet.update', 'pet.list',
        'vaccination.create', 'vaccination.read', 'vaccination.update', 'vaccination.list',
        'health.create', 'health.read', 'health.update', 'health.list',
        'announcement.read', 'announcement.list',
        'stats.view'
      ],
      'volunteer': [
        'pet.read', 'pet.list',
        'vaccination.read', 'vaccination.list',
        'health.read', 'health.list',
        'announcement.read', 'announcement.list'
      ],
      'owner': [
        'pet.read', 'pet.list',
        'vaccination.read', 'vaccination.list',
        'health.read', 'health.list',
        'announcement.read', 'announcement.list'
      ],
      'citizen': [
        'pet.read', 'pet.list',
        'announcement.read', 'announcement.list'
      ]
    };

    console.log('Mapping roles to permissions:', roles);
    const permissions = roles.reduce((acc, role) => {
      const rolePerms = rolePermissions[role] || [];
      console.log(`Permissions for role ${role}:`, rolePerms);
      return [...acc, ...rolePerms];
    }, []);

    const uniquePermissions = [...new Set(permissions)];
    console.log('Final unique permissions:', uniquePermissions);
    return uniquePermissions;
  };

  const hasPermission = (requiredPermission) => {
    if (!currentUser || !currentUser.permissions) {
      console.log('Permission check failed: No user or permissions', {
        currentUser,
        requiredPermission
      });
      return false;
    }

    const hasPermission = currentUser.permissions.includes(requiredPermission);
    console.log('Permission check:', {
      requiredPermission,
      hasPermission,
      userPermissions: currentUser.permissions,
      currentUser: currentUser.username
    });
    return hasPermission;
  };

  const value = {
    currentUser,
    login,
    logout,
    hasPermission,
    apiCall,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
