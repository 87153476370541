import { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';

const API_URL = process.env.REACT_APP_API_URL || 'https://petdb.yoda-md.workers.dev';

export function useApi() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { currentUser } = useAuth();

  const apiCall = async (endpoint, method = 'GET', body = null) => {
    setLoading(true);
    setError(null);

    try {
      const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      };

      if (currentUser && currentUser.token) {
        headers['Authorization'] = `Bearer ${currentUser.token}`;
      }

      const config = {
        method,
        headers,
        mode: 'cors',
        credentials: 'omit'
      };

      if (body) {
        config.body = JSON.stringify(body);
      }

      const response = await fetch(`${API_URL}${endpoint}`, config);

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'API call failed');
      }

      const data = await response.json();
      setLoading(false);
      return data;
    } catch (err) {
      console.error("API call error:", err);
      setError(err.message);
      setLoading(false);
      throw err;
    }
  };

  return { apiCall, loading, error };
}
