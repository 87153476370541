import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Button, Container, Typography } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useAuth } from '../contexts/AuthContext';

function NotFound() {
  const { currentUser } = useAuth();

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '60vh',
          textAlign: 'center',
        }}
      >
        <ErrorOutlineIcon 
          sx={{ 
            fontSize: 100, 
            color: 'error.main',
            mb: 2 
          }} 
        />
        
        <Typography variant="h2" component="h1" gutterBottom>
          404
        </Typography>
        
        <Typography variant="h5" component="h2" gutterBottom color="text.secondary">
          Page Not Found
        </Typography>
        
        <Typography variant="body1" color="text.secondary" paragraph>
          The page you are looking for might have been removed, had its name changed,
          or is temporarily unavailable.
        </Typography>

        <Box sx={{ mt: 4, display: 'flex', gap: 2 }}>
          <Button
            component={RouterLink}
            to={currentUser ? "/dashboard" : "/"}
            variant="contained"
            color="primary"
          >
            {currentUser ? "Go to Dashboard" : "Go to Home"}
          </Button>
          
          {!currentUser && (
            <Button
              component={RouterLink}
              to="/login"
              variant="outlined"
              color="primary"
            >
              Login
            </Button>
          )}
        </Box>
      </Box>
    </Container>
  );
}

export default NotFound;
