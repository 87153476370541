import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import { SnackbarProvider } from 'notistack';
import { QueryClient, QueryClientProvider } from 'react-query';
import Layout from './components/Layout';
import PrivateRoute from './components/PrivateRoute';

// Pages
import Home from './pages/Home';
import Login from './pages/Login';
import Register from './pages/Register';
import Dashboard from './pages/Dashboard';
import Users from './pages/Users';  // Fixed import path
import Pets from './pages/Pets';
import Clinics from './pages/Clinics';
import Vaccinations from './pages/Vaccinations';
import HealthRecords from './pages/HealthRecords';
import Volunteers from './pages/Volunteers';
import Announcements from './pages/Announcements';
import Statistics from './pages/Statistics';
import AuditLogs from './pages/AuditLogs';
import Profile from './pages/Profile';
import NotFound from './pages/NotFound';

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        autoHideDuration={3000}
      >
        <AuthProvider>
          <Router>
            <Layout>
              <Routes>
                {/* Public routes */}
                <Route path="/" element={<Home />} />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />

                {/* Protected routes with permission checks */}
                <Route
                  path="/dashboard"
                  element={
                    <PrivateRoute>
                      <Dashboard />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/profile"
                  element={
                    <PrivateRoute>
                      <Profile />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/users"
                  element={
                    <PrivateRoute requiredPermission="user.list">
                      <Users />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/pets"
                  element={
                    <PrivateRoute requiredPermission="pet.list">
                      <Pets />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/clinics"
                  element={
                    <PrivateRoute requiredPermission="clinic.list">
                      <Clinics />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/vaccinations"
                  element={
                    <PrivateRoute requiredPermission="vaccination.list">
                      <Vaccinations />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/health-records"
                  element={
                    <PrivateRoute requiredPermission="health.list">
                      <HealthRecords />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/volunteers"
                  element={
                    <PrivateRoute requiredPermission="volunteer.list">
                      <Volunteers />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/announcements"
                  element={
                    <PrivateRoute requiredPermission="announcement.list">
                      <Announcements />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/statistics"
                  element={
                    <PrivateRoute requiredPermission="stats.view">
                      <Statistics />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/audit-logs"
                  element={
                    <PrivateRoute requiredPermission="audit.view">
                      <AuditLogs />
                    </PrivateRoute>
                  }
                />

                {/* Catch all route for 404 */}
                <Route path="*" element={<NotFound />} />
              </Routes>
            </Layout>
          </Router>
        </AuthProvider>
      </SnackbarProvider>
    </QueryClientProvider>
  );
}

console.log("Build version:", process.env.REACT_APP_BUILD_VERSION);

export default App;
