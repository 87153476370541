import React, { useState } from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Container,
  Avatar,
  Button,
  Tooltip,
  MenuItem,
  Divider,
  styled,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PetsIcon from '@mui/icons-material/Pets';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import VaccinesIcon from '@mui/icons-material/Vaccines';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import CampaignIcon from '@mui/icons-material/Campaign';
import PeopleIcon from '@mui/icons-material/People';
import BarChartIcon from '@mui/icons-material/BarChart';
import SecurityIcon from '@mui/icons-material/Security';
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import { useAuth } from '../contexts/AuthContext';

// Styled components
const StyledAppBar = styled(AppBar)(({ theme }) => ({
  background: 'linear-gradient(135deg, #1976d2 0%, #1565c0 100%)',
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
}));

const StyledButton = styled(Button)(({ theme, active }) => ({
  minWidth: 'auto',
  padding: '6px 12px',
  margin: '0 4px',
  color: 'white',
  position: 'relative',
  fontSize: '0.875rem',
  whiteSpace: 'nowrap',
  '&:hover': {
    background: 'rgba(255, 255, 255, 0.1)',
    transform: 'translateY(-2px)',
    transition: 'all 0.3s ease',
  },
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: '50%',
    width: active ? '100%' : '0%',
    height: '2px',
    background: 'white',
    transform: 'translateX(-50%)',
    transition: 'width 0.3s ease',
  },
  '& .MuiSvgIcon-root': {
    fontSize: '1.2rem',
    marginRight: '2px',
    transition: 'transform 0.3s ease',
  },
  '& .MuiButton-startIcon': {
    marginRight: '4px',
  },
  '&:hover .MuiSvgIcon-root': {
    transform: 'rotate(360deg)',
  },
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  padding: '8px 16px',
  gap: theme.spacing(1),
  transition: 'all 0.3s ease',
  '& .MuiSvgIcon-root': {
    fontSize: '1.2rem',
    transition: 'transform 0.3s ease',
  },
  '&:hover': {
    background: 'rgba(25, 118, 210, 0.08)',
    transform: 'translateX(4px)',
    '& .MuiSvgIcon-root': {
      transform: 'rotate(360deg)',
    },
  },
  '&.Mui-selected': {
    background: 'rgba(25, 118, 210, 0.12)',
    '&:hover': {
      background: 'rgba(25, 118, 210, 0.16)',
    },
  },
}));

const LogoText = styled(Typography)(({ theme }) => ({
  background: 'linear-gradient(45deg, #FFF 30%, #E3F2FD 90%)',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  fontWeight: 700,
  letterSpacing: '1px',
  transition: 'transform 0.3s ease',
  '&:hover': {
    transform: 'scale(1.05)',
  },
}));

function Navbar() {
  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser, logout, hasPermission } = useAuth();
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);

  const pages = [
    { title: 'Dashboard', path: '/dashboard', permission: null, icon: <DashboardIcon /> },
    { title: 'Pets', path: '/pets', permission: 'pet.list', icon: <PetsIcon /> },
    { title: 'Clinics', path: '/clinics', permission: 'clinic.list', icon: <LocalHospitalIcon /> },
    { title: 'Vaccinations', path: '/vaccinations', permission: 'vaccination.list', icon: <VaccinesIcon /> },
    { title: 'Health Records', path: '/health-records', permission: 'health.list', icon: <HealthAndSafetyIcon /> },
    { title: 'Volunteers', path: '/volunteers', permission: 'volunteer.list', icon: <VolunteerActivismIcon /> },
    { title: 'Announcements', path: '/announcements', permission: 'announcement.list', icon: <CampaignIcon /> },
    { title: 'Users', path: '/users', permission: 'user.list', icon: <PeopleIcon /> },
    { title: 'Statistics', path: '/statistics', permission: 'stats.view', icon: <BarChartIcon /> },
    { title: 'Audit Logs', path: '/audit-logs', permission: 'audit.view', icon: <SecurityIcon /> },
  ];

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    handleCloseUserMenu();
    logout();
    navigate('/');
  };

  const handleNavigate = (path) => {
    handleCloseNavMenu();
    navigate(path);
  };

  const authorizedPages = pages.filter(page =>
    !page.permission || (currentUser && hasPermission(page.permission))
  );

  return (
    <StyledAppBar position="static">
      <Container maxWidth={false}>
        <Toolbar disableGutters sx={{ minHeight: '56px' }}>
          {/* Logo/Brand - Desktop */}
          <Box sx={{ width: '140px', display: { xs: 'none', md: 'flex' } }}>
            <LogoText
              variant="h6"
              noWrap
              component={RouterLink}
              to="/"
              sx={{
                textDecoration: 'none',
                fontSize: '1.2rem',
              }}
            >
              PetDB
            </LogoText>
          </Box>

          {/* Mobile Menu */}
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
              sx={{
                '&:hover': {
                  transform: 'rotate(180deg)',
                  transition: 'transform 0.3s ease',
                },
              }}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
                '& .MuiPaper-root': {
                  borderRadius: '10px',
                  marginTop: '8px',
                  minWidth: '200px',
                  boxShadow: '0 8px 16px rgba(0,0,0,0.1)',
                },
              }}
            >
              {authorizedPages.map((page) => (
                <StyledMenuItem
                  key={page.path}
                  onClick={() => handleNavigate(page.path)}
                  selected={location.pathname === page.path}
                >
                  {page.icon}
                  <Typography>{page.title}</Typography>
                </StyledMenuItem>
              ))}
            </Menu>
          </Box>

          {/* Logo/Brand - Mobile */}
          <LogoText
            variant="h5"
            noWrap
            component={RouterLink}
            to="/"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              textDecoration: 'none',
              fontSize: '1.2rem',
            }}
          >
            PetDB
          </LogoText>

          {/* Desktop Menu */}
          <Box sx={{
            display: { xs: 'none', md: 'flex' },
            flex: 1,
            justifyContent: 'center',
            overflow: 'auto',
            mx: 2,
            '&::-webkit-scrollbar': {
              height: '4px',
            },
            '&::-webkit-scrollbar-track': {
              background: 'transparent',
            },
            '&::-webkit-scrollbar-thumb': {
              background: 'rgba(255, 255, 255, 0.3)',
              borderRadius: '2px',
            },
            '&::-webkit-scrollbar-thumb:hover': {
              background: 'rgba(255, 255, 255, 0.5)',
            },
          }}>
            {authorizedPages.map((page) => (
              <StyledButton
                key={page.path}
                onClick={() => handleNavigate(page.path)}
                active={location.pathname === page.path ? 1 : 0}
                startIcon={page.icon}
              >
                {page.title}
              </StyledButton>
            ))}
          </Box>

          {/* User Menu */}
          <Box sx={{ width: '140px', display: 'flex', justifyContent: 'flex-end' }}>
            {currentUser ? (
              <Box sx={{ flexGrow: 0 }}>
                <Tooltip title="Open settings">
                  <IconButton
                    onClick={handleOpenUserMenu}
                    sx={{
                      p: 0,
                      transition: 'transform 0.3s ease',
                      '&:hover': {
                        transform: 'scale(1.1)',
                      },
                    }}
                  >
                    <Avatar
                      alt={currentUser.name}
                      sx={{
                        width: 32,
                        height: 32,
                        bgcolor: 'secondary.main',
                        boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                      }}
                    >
                      {currentUser.name ? currentUser.name[0].toUpperCase() : 'U'}
                    </Avatar>
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{
                    mt: '45px',
                    '& .MuiPaper-root': {
                      borderRadius: '10px',
                      minWidth: '200px',
                      boxShadow: '0 8px 16px rgba(0,0,0,0.1)',
                    },
                  }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <StyledMenuItem onClick={() => {
                    handleCloseUserMenu();
                    navigate('/profile');
                  }}>
                    <PersonIcon />
                    <Typography>Profile</Typography>
                  </StyledMenuItem>
                  <Divider sx={{ my: 1 }} />
                  <StyledMenuItem onClick={handleLogout}>
                    <LogoutIcon />
                    <Typography>Logout</Typography>
                  </StyledMenuItem>
                </Menu>
              </Box>
            ) : (
              <Box sx={{ display: 'flex', gap: 1 }}>
                <Button
                  component={RouterLink}
                  to="/login"
                  color="inherit"
                  sx={{
                    borderRadius: '20px',
                    px: 2,
                    '&:hover': {
                      background: 'rgba(255, 255, 255, 0.1)',
                      transform: 'translateY(-2px)',
                      transition: 'all 0.3s ease',
                    },
                  }}
                >
                  Login
                </Button>
                <Button
                  component={RouterLink}
                  to="/register"
                  color="inherit"
                  variant="outlined"
                  sx={{
                    borderRadius: '20px',
                    px: 2,
                    borderColor: 'white',
                    '&:hover': {
                      background: 'rgba(255, 255, 255, 0.1)',
                      borderColor: 'white',
                      transform: 'translateY(-2px)',
                      transition: 'all 0.3s ease',
                    },
                  }}
                >
                  Register
                </Button>
              </Box>
            )}
          </Box>
        </Toolbar>
      </Container>
    </StyledAppBar>
  );
}

export default Navbar;
