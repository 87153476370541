// @ts-nocheck
import React, { useState, useCallback } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useApi } from '../hooks/useApi';
import { useSnackbar } from 'notistack';
import { useAuth } from '../contexts/AuthContext';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Chip,
  FormControl,
  Select,
  MenuItem,
  FormGroup,
  FormLabel,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SettingsIcon from '@mui/icons-material/Settings';
import { PERMISSIONS, ROLE_PERMISSIONS, ROLE_HIERARCHY, formatRole } from '../utils/permissions';

// New role management dialog
const RolePermissionDialog = ({ role, onClose }) => {
  const [permissions, setPermissions] = useState(ROLE_PERMISSIONS[role] || []);
  const { apiCall } = useApi();
  const { enqueueSnackbar}= useSnackbar();
  const queryClient = useQueryClient();

  const handlePermissionChange = async (perm, checked) => {
    try {
      if (checked) {
        await apiCall(`/api/roles/${role}/permissions`, 'POST', { permission: perm });
        setPermissions(prev => [...prev, perm]);
      } else {
        await apiCall(`/api/roles/${role}/permissions/${perm}`, 'DELETE');
        setPermissions(prev => prev.filter(p => p !== perm));
      }
      queryClient.invalidateQueries('users');
      enqueueSnackbar('Permission updated successfully', { variant: 'success' });
    } catch (error) {
      console.error('Error updating permission:', error);
      enqueueSnackbar(`Error updating permission: ${error.message}`, { variant: 'error' });
  }
  };

  return (
    <Dialog open onClose={onClose}>
      <DialogTitle>Manage {formatRole(role)} Permissions</DialogTitle>
      <DialogContent>
        {Object.entries(PERMISSIONS).map(([group, perms]) => (
          <FormGroup key={group}>
            <FormLabel>{group}</FormLabel>
            {Object.entries(perms).map(([key, perm]) => (
              <FormControlLabel
                key={perm}
                control={<Checkbox
                  checked={permissions.includes(perm)}
                  onChange={e => handlePermissionChange(perm, e.target.checked)}
                />}
                label={key}
              />
            ))}
          </FormGroup>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

// Enhance role selection in Users.js
const RoleSelect = ({ value, onChange }) => {
  const { hasPermission } = useAuth();
  const [manageRole, setManageRole] = useState(null);

  const handleRoleClick = (role) => {
    // Handle role removal
    onChange(value.filter(r => r !== role));
  };

  const handleManageRole = (role, event) => {
    event.stopPropagation();
    setManageRole(role);
  };

  return (
    <Box>
      <FormControl fullWidth>
        <Select
          multiple
          value={value}
          onChange={onChange}
          renderValue={selected => (
            <Box sx={{ display: 'flex', gap: 0.5 }}>
              {selected.map(role => (
                <Chip
                  key={role}
                  label={formatRole(role)}
                  onDelete={hasPermission('role.manage') ?
                    () => handleRoleClick(role) : undefined}
                />
              ))}
            </Box>
          )}
        >
          {ROLE_HIERARCHY.map(role => (
            <MenuItem key={role} value={role}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <Typography>{formatRole(role)}</Typography>
                {hasPermission('role.manage') && (
                  <IconButton size="small" onClick={(e) => handleManageRole(role, e)}>
                    <SettingsIcon />
                  </IconButton>
                )}
              </Box>
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {manageRole && (
        <RolePermissionDialog
          role={manageRole}
          onClose={() => setManageRole(null)}
        />
      )}
    </Box>
  );
};

function Users() {
  console.log('Users component rendered');

  const { hasPermission } = useAuth();
  const [userDialogOpen, setUserDialogOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const { apiCall } = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { data: usersData, isLoading } = useQuery(
    'users',
    () => apiCall('/api/users'),
    {
      enabled: true,
      onError: (error) => {
        console.error('Error fetching users:', error);
        enqueueSnackbar(`Error fetching users: ${error.message}`, { variant: 'error' });
      }
    }
  );

  const users = usersData?.data || [];

  const createUserMutation = useMutation(
    (newUser) => {
      const userData = {
        username: newUser.username,
        name: newUser.name,
        email: newUser.email,
        password: newUser.password,
        roles: newUser.roles,
        district_id: 1, // Default to first district
        phone: newUser.phone || null,
        address: newUser.address || null,
        idnp: newUser.idnp || null
      };
      console.log('Creating new user:', userData);
      return apiCall('/api/users', 'POST', userData);
    },
    {
      onSuccess: () => {
        console.log('User created successfully');
        queryClient.invalidateQueries('users');
        enqueueSnackbar('User created successfully', { variant: 'success' });
        setUserDialogOpen(false);
      },
      onError: (error) => {
        console.error('Error creating user:', error);
        enqueueSnackbar(`Error creating user: ${error.message}`, { variant: 'error' });
      },
    }
  );

  const updateUserMutation = useMutation(
    (updatedUser) => {
      const userData = {
        ...updatedUser,
        district_id: updatedUser.district_id || 1,
        phone: updatedUser.phone || null,
        address: updatedUser.address || null,
        idnp: updatedUser.idnp || null
      };
      console.log('Updating user:', userData);
      return apiCall(`/api/users/${userData.id}`, 'PUT', userData);
    },
    {
      onSuccess: () => {
        console.log('User updated successfully');
        queryClient.invalidateQueries('users');
        enqueueSnackbar('User updated successfully', { variant: 'success' });
        setUserDialogOpen(false);
      },
      onError: (error) => {
        console.error('Error updating user:', error);
        enqueueSnackbar(`Error updating user: ${error.message}`, { variant: 'error' });
      },
    }
  );

  const deleteUserMutation = useMutation(
    (userId) => {
      console.log('Deleting user:', userId);
      return apiCall(`/api/users/${userId}`, 'DELETE');
    },
    {
      onSuccess: () => {
        console.log('User deleted successfully');
        queryClient.invalidateQueries('users');
        enqueueSnackbar('User deleted successfully', { variant: 'success' });
      },
      onError: (error) => {
        console.error('Error deleting user:', error);
        enqueueSnackbar(`Error deleting user: ${error.message}`, { variant: 'error' });
      },
    }
  );

  const handleUserDialogOpen = useCallback((user = null) => {
    console.log('handleUserDialogOpen called with user:', user);
    setSelectedUser(user);
    setSelectedRoles(user?.roles || []);
    setUserDialogOpen(true);
  }, []);

  const handleUserDialogClose = useCallback(() => {
    console.log('handleUserDialogClose called');
    setUserDialogOpen(false);
    setSelectedUser(null);
    setSelectedRoles([]);
  }, []);

  const handleUserSubmit = useCallback((event) => {
    event.preventDefault();
    console.log('Form submitted');

    const formData = new FormData(event.currentTarget);
    const userData = {
      username: formData.get('username').trim(),
      name: formData.get('name').trim(),
      email: formData.get('email').trim(),
      password: formData.get('password'),
      roles: selectedRoles,
      district_id: selectedUser?.district_id || 1,
      phone: formData.get('phone')?.trim() || null,
      address: formData.get('address')?.trim() || null,
      idnp: formData.get('idnp')?.trim() || null
    };

    console.log('Processed user data:', userData);

    if (selectedUser) {
      updateUserMutation.mutate({ id: selectedUser.id, ...userData });
    } else {
      createUserMutation.mutate(userData);
    }
  }, [selectedUser, createUserMutation, updateUserMutation, selectedRoles]);

  if (isLoading) {
    return <Typography>Loading users...</Typography>;
  }

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant="h5">Users</Typography>
        {hasPermission('user.create') && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              console.log('Add User button clicked');
              handleUserDialogOpen();
            }}
            id="add-user-button"
          >
            Add User
          </Button>
        )}
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Username</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Roles</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow key={user.id}>
                <TableCell>{user.username}</TableCell>
                <TableCell>{user.name}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.phone}</TableCell>
                <TableCell>
                  <RoleSelect
                    value={user.roles || []}
                    onChange={(newRoles) => {
                      updateUserMutation.mutate({
                        id: user.id,
                        ...user,
                        roles: newRoles
                      });
                    }}
                  />
                </TableCell>
                <TableCell>
                  {hasPermission('user.update') && (
                    <IconButton
                      onClick={() => handleUserDialogOpen(user)}
                      size="small"
                      sx={{ mr: 1 }}
                    >
                      <EditIcon />
                    </IconButton>
                  )}
                  {hasPermission('user.delete') && (
                    <IconButton
                      onClick={() => deleteUserMutation.mutate(user.id)}
                      size="small"
                      color="error"
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog
        open={userDialogOpen}
        onClose={handleUserDialogClose}
        maxWidth="sm"
        fullWidth
      >
        <form onSubmit={handleUserSubmit}>
          <DialogTitle>{selectedUser ? 'Edit User' : 'Add User'}</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              name="username"
              label="Username"
              type="text"
              fullWidth
              defaultValue={selectedUser?.username || ''}
              required
            />
            <TextField
              margin="dense"
              name="name"
              label="Name"
              type="text"
              fullWidth
              defaultValue={selectedUser?.name || ''}
              required
            />
            <TextField
              margin="dense"
              name="email"
              label="Email"
              type="email"
              fullWidth
              defaultValue={selectedUser?.email || ''}
              required
            />
            {!selectedUser && (
              <TextField
                margin="dense"
                name="password"
                label="Password"
                type="password"
                fullWidth
                required
              />
            )}
            <TextField
              margin="dense"
              name="phone"
              label="Phone (optional)"
              type="text"
              fullWidth
              defaultValue={selectedUser?.phone || ''}
            />
            <TextField
              margin="dense"
              name="address"
              label="Address (optional)"
              type="text"
              fullWidth
              defaultValue={selectedUser?.address || ''}
            />
            <TextField
              margin="dense"
              name="idnp"
              label="IDNP (optional)"
              type="text"
              fullWidth
              defaultValue={selectedUser?.idnp || ''}
            />
            <RoleSelect
              value={selectedRoles}
              onChange={(event) => {
                const {
                  target: { value },
                } = event;
                setSelectedRoles(typeof value === 'string' ? value.split(',') : value);
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleUserDialogClose}>Cancel</Button>
            <Button type="submit" variant="contained">
              {selectedUser ? 'Update' : 'Create'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  );
}

export default Users;
