/**
 * Permission utility functions for the client-side application
 */

// Role hierarchy (from highest to lowest)
export const ROLE_HIERARCHY = [
  'superadmin',
  'staff',
  'volunteer',
  'owner',
  'citizen'
];

// Permission definitions
export const PERMISSIONS = {
  USER: {
    CREATE: 'user.create',
    READ: 'user.read',
    UPDATE: 'user.update',
    DELETE: 'user.delete',
    LIST: 'user.list'
  },
  PET: {
    CREATE: 'pet.create',
    READ: 'pet.read',
    UPDATE: 'pet.update',
    DELETE: 'pet.delete',
    LIST: 'pet.list'
  },
  CLINIC: {
    CREATE: 'clinic.create',
    READ: 'clinic.read',
    UPDATE: 'clinic.update',
    DELETE: 'clinic.delete',
    LIST: 'clinic.list'
  },
  VACCINATION: {
    CREATE: 'vaccination.create',
    READ: 'vaccination.read',
    UPDATE: 'vaccination.update',
    DELETE: 'vaccination.delete',
    LIST: 'vaccination.list'
  },
  HEALTH: {
    CREATE: 'health.create',
    READ: 'health.read',
    UPDATE: 'health.update',
    DELETE: 'health.delete',
    LIST: 'health.list'
  },
  VOLUNTEER: {
    CREATE: 'volunteer.create',
    READ: 'volunteer.read',
    UPDATE: 'volunteer.update',
    DELETE: 'volunteer.delete',
    LIST: 'volunteer.list'
  },
  ANNOUNCEMENT: {
    CREATE: 'announcement.create',
    READ: 'announcement.read',
    UPDATE: 'announcement.update',
    DELETE: 'announcement.delete',
    LIST: 'announcement.list'
  },
  SYSTEM: {
    AUDIT: 'audit.view',
    CONFIGURE: 'system.configure',
    STATS: 'stats.view',
    ROLE_MANAGE: 'role.manage'
  }
};

// Role permission mappings
export const ROLE_PERMISSIONS = {
  superadmin: [...Object.values(PERMISSIONS).flatMap(group => Object.values(group)), PERMISSIONS.SYSTEM.ROLE_MANAGE],
  staff: [
    PERMISSIONS.PET.CREATE,
    PERMISSIONS.PET.READ,
    PERMISSIONS.PET.UPDATE,
    PERMISSIONS.PET.LIST,
    PERMISSIONS.VACCINATION.CREATE,
    PERMISSIONS.VACCINATION.READ,
    PERMISSIONS.VACCINATION.UPDATE,
    PERMISSIONS.VACCINATION.LIST,
    PERMISSIONS.HEALTH.CREATE,
    PERMISSIONS.HEALTH.READ,
    PERMISSIONS.HEALTH.UPDATE,
    PERMISSIONS.HEALTH.LIST,
    PERMISSIONS.ANNOUNCEMENT.READ,
    PERMISSIONS.ANNOUNCEMENT.LIST,
    PERMISSIONS.SYSTEM.STATS
  ],
  volunteer: [
    PERMISSIONS.PET.READ,
    PERMISSIONS.PET.LIST,
    PERMISSIONS.VACCINATION.READ,
    PERMISSIONS.VACCINATION.LIST,
    PERMISSIONS.HEALTH.READ,
    PERMISSIONS.HEALTH.LIST,
    PERMISSIONS.ANNOUNCEMENT.READ,
    PERMISSIONS.ANNOUNCEMENT.LIST
  ],
  owner: [
    PERMISSIONS.PET.READ,
    PERMISSIONS.PET.LIST,
    PERMISSIONS.VACCINATION.READ,
    PERMISSIONS.VACCINATION.LIST,
    PERMISSIONS.HEALTH.READ,
    PERMISSIONS.HEALTH.LIST,
    PERMISSIONS.ANNOUNCEMENT.READ,
    PERMISSIONS.ANNOUNCEMENT.LIST
  ],
  citizen: [
    PERMISSIONS.PET.READ,
    PERMISSIONS.PET.LIST,
    PERMISSIONS.ANNOUNCEMENT.READ,
    PERMISSIONS.ANNOUNCEMENT.LIST
  ]
};

/**
 * Check if a role has a specific permission
 * @param {string} role - The role to check
 * @param {string} permission - The permission to check for
 * @returns {boolean} - Whether the role has the permission
 */
export function roleHasPermission(role, permission) {
  return ROLE_PERMISSIONS[role]?.includes(permission) || false;
}

/**
 * Check if a role is higher in hierarchy than another
 * @param {string} role1 - First role to compare
 * @param {string} role2 - Second role to compare
 * @returns {boolean} - Whether role1 is higher than role2
 */
export function isRoleHigher(role1, role2) {
  return ROLE_HIERARCHY.indexOf(role1) < ROLE_HIERARCHY.indexOf(role2);
}

/**
 * Get all permissions for a set of roles
 * @param {string[]} roles - Array of roles
 * @returns {string[]} - Array of unique permissions
 */
export function getRolePermissions(roles) {
  return [...new Set(
    roles.flatMap(role => ROLE_PERMISSIONS[role] || [])
  )];
}

/**
 * Check if user has required permission through any of their roles
 * @param {string[]} userRoles - Array of user's roles
 * @param {string} requiredPermission - Permission to check for
 * @returns {boolean} - Whether user has the permission
 */
export function checkPermission(userRoles, requiredPermission) {
  return userRoles.some(role => roleHasPermission(role, requiredPermission));
}

/**
 * Get the highest role from an array of roles
 * @param {string[]} roles - Array of roles to check
 * @returns {string} - Highest role in the hierarchy
 */
export function getHighestRole(roles) {
  return roles.reduce((highest, current) =>
    isRoleHigher(current, highest) ? current : highest
  );
}

/**
 * Check if user can manage (edit/delete) a resource
 * @param {Object} user - Current user object
 * @param {Object} resource - Resource to check
 * @param {string} resourceType - Type of resource (e.g., 'pet', 'health_record')
 * @returns {boolean} - Whether user can manage the resource
 */
export function canManageResource(user, resource, resourceType) {
  // Superadmin can manage everything
  if (user.roles.includes('superadmin')) return true;

  // Check if user owns the resource
  if (resource.owner_id === user.id) return true;

  // Staff can manage certain resources
  if (user.roles.includes('staff')) {
    const staffManageableResources = ['pet', 'vaccination', 'health_record'];
    return staffManageableResources.includes(resourceType);
  }

  return false;
}

/**
 * Get available actions for a resource based on user permissions
 * @param {Object} user - Current user object
 * @param {string} resourceType - Type of resource
 * @returns {Object} - Object with boolean flags for each action
 */
export function getResourceActions(user, resourceType) {
  const permissions = getRolePermissions(user.roles);
  const resourceGroup = PERMISSIONS[resourceType.toUpperCase()];

  return {
    canCreate: permissions.includes(resourceGroup?.CREATE),
    canRead: permissions.includes(resourceGroup?.READ),
    canUpdate: permissions.includes(resourceGroup?.UPDATE),
    canDelete: permissions.includes(resourceGroup?.DELETE),
    canList: permissions.includes(resourceGroup?.LIST)
  };
}

/**
 * Format role for display
 * @param {string} role - Role to format
 * @returns {string} - Formatted role name
 */
export function formatRole(role) {
  return role.charAt(0).toUpperCase() + role.slice(1);
}

/**
 * Get navigation menu items based on user permissions
 * @param {Object} user - Current user object
 * @returns {Array} - Array of menu items user has access to
 */
export function getNavMenuItems(user) {
  const permissions = getRolePermissions(user.roles);
  const menuItems = [];

  // Dashboard is available to all authenticated users
  menuItems.push({ path: '/dashboard', label: 'Dashboard' });

  if (permissions.includes(PERMISSIONS.USER.LIST)) {
    menuItems.push({ path: '/users', label: 'Users' });
  }

  if (permissions.includes(PERMISSIONS.PET.LIST)) {
    menuItems.push({ path: '/pets', label: 'Pets' });
  }

  if (permissions.includes(PERMISSIONS.CLINIC.LIST)) {
    menuItems.push({ path: '/clinics', label: 'Clinics' });
  }

  if (permissions.includes(PERMISSIONS.VACCINATION.LIST)) {
    menuItems.push({ path: '/vaccinations', label: 'Vaccinations' });
  }

  if (permissions.includes(PERMISSIONS.HEALTH.LIST)) {
    menuItems.push({ path: '/health-records', label: 'Health Records' });
  }

  if (permissions.includes(PERMISSIONS.VOLUNTEER.LIST)) {
    menuItems.push({ path: '/volunteers', label: 'Volunteers' });
  }

  if (permissions.includes(PERMISSIONS.ANNOUNCEMENT.LIST)) {
    menuItems.push({ path: '/announcements', label: 'Announcements' });
  }

  if (permissions.includes(PERMISSIONS.SYSTEM.STATS)) {
    menuItems.push({ path: '/statistics', label: 'Statistics' });
  }

  if (permissions.includes(PERMISSIONS.SYSTEM.AUDIT)) {
    menuItems.push({ path: '/audit-logs', label: 'Audit Logs' });
  }

  return menuItems;
}
