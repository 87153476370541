import React from 'react';
import { Link } from 'react-router-dom';
import { Typography, Button, Box, Container, Grid, Paper } from '@mui/material';
import PetsIcon from '@mui/icons-material/Pets';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import VaccinesIcon from '@mui/icons-material/Vaccines';
import { useAuth } from '../contexts/AuthContext';

function Home() {
  const { currentUser } = useAuth();

  return (
    <Container maxWidth="lg">
      <Box sx={{ my: 4, textAlign: 'center' }}>
        <Typography variant="h2" component="h1" gutterBottom>
          Welcome to PetDB
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom color="text.secondary">
          Manage your pets, clinics, and vaccinations all in one place.
        </Typography>
        
        {!currentUser && (
          <Box sx={{ mt: 4, mb: 8 }}>
            <Button component={Link} to="/login" variant="contained" color="primary" size="large" sx={{ mr: 2 }}>
              Login
            </Button>
            <Button component={Link} to="/register" variant="contained" color="secondary" size="large">
              Register
            </Button>
          </Box>
        )}

        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Paper elevation={3} sx={{ p: 3 }}>
              <PetsIcon sx={{ fontSize: 60, color: 'primary.main' }} />
              <Typography variant="h5" component="h3" gutterBottom>
                Pet Management
              </Typography>
              <Typography variant="body1">
                Keep track of your pets' information, health records, and vaccinations.
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper elevation={3} sx={{ p: 3 }}>
              <LocalHospitalIcon sx={{ fontSize: 60, color: 'primary.main' }} />
              <Typography variant="h5" component="h3" gutterBottom>
                Clinic Directory
              </Typography>
              <Typography variant="body1">
                Find and manage information about veterinary clinics in your area.
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper elevation={3} sx={{ p: 3 }}>
              <VaccinesIcon sx={{ fontSize: 60, color: 'primary.main' }} />
              <Typography variant="h5" component="h3" gutterBottom>
                Vaccination Tracking
              </Typography>
              <Typography variant="body1">
                Stay up-to-date with your pets' vaccinations and receive reminders.
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

export default Home;
