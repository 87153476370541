import React from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useQuery } from 'react-query';
import { useApi } from '../hooks/useApi';
import { Typography, Paper, Box, Grid, Card, CardContent, CircularProgress } from '@mui/material';

function Dashboard() {
  const { currentUser } = useAuth();
  const { apiCall } = useApi();

  const {
    data: userData,
    isLoading,
    isError,
    error
  } = useQuery(
    ['user', currentUser?.id],
    () => apiCall(`/api/users/${currentUser?.id}`),
    {
      enabled: !!currentUser?.id,
      retry: 3,
      onError: (error) => console.error("Error fetching user data:", error),
    }
  );

  console.log("Current user:", currentUser);
  console.log("User data:", userData);

  if (isLoading) {
    return <CircularProgress />;
  }

  if (isError) {
    return <Typography color="error">Error loading user data: {error.message}</Typography>;
  }

  if (!userData) {
    return <Typography>No user data available. Please try refreshing the page.</Typography>;
  }

  const roles = Array.isArray(userData.roles) ? userData.roles : (userData.roles ? [userData.roles] : []);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Typography variant="h4" gutterBottom>Dashboard</Typography>
      <Grid container spacing={3}>
        {/* Main content */}
        <Grid item xs={12} md={8}>
          <Paper elevation={3} sx={{ p: 3, mt: 2 }}>
            <Typography variant="h6" gutterBottom>Main Content</Typography>
            <Typography>This is where the main dashboard content will go.</Typography>
          </Paper>
        </Grid>

        {/* Sidebar */}
        <Grid item xs={12} md={4}>
          <Card elevation={3} sx={{ mt: 2 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>User Information</Typography>
              <Typography>ID: {userData.id}</Typography>
              <Typography>Username: {userData.username}</Typography>
              <Typography>Name: {userData.name}</Typography>
              <Typography>Email: {userData.email}</Typography>
              <Typography>Roles: {roles.join(', ')}</Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Dashboard;
